import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function OurCustomers(props) {
  const classes = useStyles();
  return (
    <section className="section">
      <div className="container">
        <div className="row items">
          <div className="col-12">
            <div className="wrapp-section-title section-title-center">
              <h2 className="section-title">Our customers</h2>
              <p className="section-desc">
                Our customers are everyday businesses like yours that have come
                to us to take their website or application to the next level.
                Could you be next?
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://www.blueskieswindows.com"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/blue_skies_logo.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt="Family-owned and operated professional cleaning company - Blue Skies Window and Gutter Cleaning Service"
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://cahomecompany.com/"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/California_Home_Company_Dark_Logo.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt="Let Us Build Your Dream Home - California Home Company"
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a href={"https://conuslasergroup.com/"}
                className={classes.link}
                target="_blank"
            >
                <div className="brands-item item-style">
                    <img
                        data-src="/img/brands/conus_laser_group_full_logo.jpg"
                        className="lazy"
                        src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        alt="CONUS Laser Group is a large-format laser cutting and engraving facility in Columbus, Ohio, serving both corporations and individuals."
                    />
                </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://www.ginzajdm.com"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/ginzajdm_logo.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt="Ginza JDM - Your premier destination for importing high-quality Japanese cars"
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://www.holidaylightsdepot.com"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/holiday_lights_depot.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt=""
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"http://reliablehomesolutions.net"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/rhs_logo.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt=""
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://riversidebuildersinc.com"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/riverside_builder_logo.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt=""
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://rockstarcarauction.com/"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/rockstar_car_auction_logo.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt=""
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://strokemyleaves.com"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/stroke_my_leaves.jpeg"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt=""
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://swrcleaning.com/"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/swr_cleaning_logo.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt="Pacific Northwest's Best Pressure Washing Services - Soft Wash Roof Cleaning (SWR)"
                />
              </div>
            </a>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6 item">
            <a
                href={"https://unitedtranservices.com"}
                className={classes.link}
                target="_blank"
            >
              <div className="brands-item item-style">
                <img
                    data-src="/img/brands/utsi_logo_sm.png"
                    className="lazy"
                    src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt=""
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
